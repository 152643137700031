import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as env from 'src/environments/environment';

import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'openChat';
  projectName = env.environment.projectName;


  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.projectName);
  }
}
